html, body {
  margin: 0;

  /* 子節點設定 height 100% */
  height: 100%;
}

header {
  height: 80px;

  background-color: #2c2c2c;
}
h3 {
  margin: 0 40px;
  line-height: 80px;
  
  color: #dbdbdb;
  font-size: 25px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

section {
  height: calc(100% - 80px);

  background-color: #727272;

  /* 子節點垂直置中 */
  display: flex;
  align-items: center;
}
#main {
  margin: auto;
}